import script from "./Stops.vue?vue&type=script&setup=true&lang=ts"
export * from "./Stops.vue?vue&type=script&setup=true&lang=ts"

import "./Stops.vue?vue&type=style&index=0&id=2fc69eed&lang=scss"

const __exports__ = script;

export default __exports__
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner,QCard,QTable,QTd,QBtn});
